export default function Landing() {
  return (
    <div
      className="flex justify-center md:items-center flex-col md:flex-row h-screen"
      id="home"
    >
      <div className="h-1/3 md:w-1/2 md:h-2/3 flex justify-center">
        <img
          src={require("~/src/assets/images/landing.webp")}
          alt="Yukidaruma logo"
          className="h-full"
        />
      </div>
      <div className="md:w-1/2">
        <h1 className="font-bubble text-4xl font-semibold text-center text-slate-400 my-6 mx-4">
          3,333 unique generative Japanese-inspired snowman NFTs
        </h1>
        <strong className="font-bubble text-6xl font-bold text-center my-2 text-azur block">
          Coming Soon
        </strong>
      </div>
    </div>
  );
}
