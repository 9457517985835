export default function Navbar() {
  return (
    <nav className="flex justify-between flex-wrap px-6 py-4 fixed w-full">
      <a href="#home">
        <div className="md:w-auto w-full flex flex-row place-items-center">
          <img
            src={require("~/src/assets/images/logo.webp")}
            alt="Yukidaruma logo"
            className="h-14"
          />
          <h1 className="font-bubble text-3xl text-slate-400 ml-2">
            YukiDaruma
          </h1>
        </div>
      </a>
      <ul className="flex flex-wrap flex-row justify-between items-center md:w-2/5 w-full font-sans text-lg font-semibold">
        <li>
          <a
            href="#home"
            className="text-slate-500 font-sans hover:text-slate-400"
          >
            Home
          </a>
        </li>
        <li>
          <a
            href="#about"
            className="text-slate-500 font-sans hover:text-slate-400"
          >
            About
          </a>
        </li>
        <li>
          <a
            href="#roadmap"
            className="text-slate-500 font-sans hover:text-slate-400"
          >
            Roadmap
          </a>
        </li>
      </ul>
      <div className="w-1/5 hidden md:flex items-center justify-end">
        <h3 className="text-md text-slate-500 font-sans font-bold">
          Follow us:
        </h3>
        <a href="https://discord.gg/mC7Wfabc9V">
          <img
            src={require("~/src/assets/images/discord-icon.svg")}
            alt="Discord logo"
            className="h-6 ml-8"
          />
        </a>
        <a href="https://twitter.com/YukiDarumaNFT">
          <img
            src={require("~/src/assets/images/twitter.svg")}
            alt="Twitter logo"
            className="h-6 ml-4"
          />
        </a>
      </div>
    </nav>
  );
}
