export default function About() {
  return (
    <div
      className="flex justify-center flex-col md:flex-row py-6 px-12 bg-slate-200"
      id="about"
    >
      <div className="md:w-1/2">
        <h4 className="font-bubble text-4xl font-semibold text-slate-500 my-6 mx-4">
          About
        </h4>
        <p className="text-slate-400 mb-12 text-lg font-sans font-semibold">
          Yukidaruma is a collection of hand-drawn randomly generated digital
          art assets that live on the avalanche blockchain. Each snowman is
          unique and is wearing different randomly generated accessories or
          items, determining the rarity and the market value of each snowman.
        </p>
      </div>
      <div className="h-1/3 md:w-1/2 md:h-2/3 flex justify-center">
        <img
          src={require("~/src/assets/images/about.webp")}
          alt="Yukidaruma logo"
          className="h-96"
        />
      </div>
    </div>
  );
}
