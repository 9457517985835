import Navbar from "./components/navbar";
import Landing from "./components/landing";
import About from "./components/about";
import Roadmap from "./components/roadmap";

export function App() {
  return (
    <>
      <Navbar />
      <Landing />
      <About />
      <Roadmap />
    </>
  );
}
