export default function Roadmap() {
  return (
    <div
      className="flex items-center flex-col py-6 px-12 bg-slate-600"
      id="roadmap"
    >
      <h4 className="font-bubble text-4xl font-semibold text-slate-300 my-6">
        Roadmap
      </h4>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl bg-slate-400 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-slate-700">September 2021</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-700 justify-center">
          <li>Brainstorming</li>
          <li>Team creation</li>
          <li>First sketches</li>
        </ul>
      </div>
      <div className="h-12 w-1 bg-slate-800"></div>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl bg-slate-400 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-slate-700">Otober 2021</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-700 justify-center">
          <li>Smart contract development</li>
          <li>First assets completed</li>
          <li>Development of the generative algorithm</li>
        </ul>
      </div>
      <div className="h-12 w-1 bg-slate-800"></div>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl bg-slate-400 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-slate-700">November 2021</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-700 justify-center">
          <li>Launch on FUJI testnet</li>
          <li>All assets completed</li>
          <li>Generation of all NFTs</li>
          <li>Website development</li>
        </ul>
      </div>
      <div className="h-12 w-1 bg-slate-800"></div>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl border-4 border-slate-200 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-azur">Ongoing</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-400 justify-center">
          <li>Twitter account created</li>
          <li>Marketing campaign</li>
          <li>Discord server created</li>
          <li>Website enhancement</li>
        </ul>
      </div>
      <div className="h-12 w-1 bg-slate-800"></div>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl bg-slate-400 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-slate-700">Upcoming</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-700 justify-center">
          <li>Marketplace listing</li>
          <li>Partnership with various projects</li>
        </ul>
      </div>
      <div className="h-12 w-1 bg-slate-800"></div>
      <div className="h-48 min-h-fit mx-12 w-full max-w-5xl bg-slate-400 rounded-md flex">
        <div className="w-1/3 md:w-1/5 flex justify-center items-center">
          <h4 className="text-center font-bold text-2xl font-bubble text-slate-700">Upcoming</h4>
        </div>
        <ul className="w-2/3 md:w-4/5 ml-6 flex flex-col list-disc font-semibold font-sans text-md text-slate-700 justify-center">
          <li>MINT!</li>
        </ul>
      </div>
    </div>
  );
}
